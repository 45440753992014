import { INTERNAL_LINK_MAP } from 'utils/constants';

/**
 * Gets the destination URL for a `SanityLink`. Will always
 * prioritize the internal link if one exists.
 *
 * @param {object} [internalLink] the internal link object that
 * holds reference to a doc or page
 * @param {string} [url] a static external url
 * @returns the destination URL
 */
const getLinkUrl = (internalLink, url) => {
  if (internalLink) {
    const { _type, ...reference } = internalLink.reference;
    return INTERNAL_LINK_MAP[_type](reference);
  }
  return url;
};

export default getLinkUrl;
