import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Nav, SEO } from 'components';
import { useDetectSSR } from 'hooks';
import styles from './layout.module.scss';

const Layout = ({ children, location }) => {
  const isSSR = useDetectSSR();

  /**
   * Updates the full height CSS variable value to the inner
   * window height on window resize.
   *
   * This functionality should only occur on iOS where 100vh
   * is not the actual height of the window.
   */
  const onResize = () => {
    document.documentElement.style.setProperty(styles.varFullHeight, `${window.innerHeight}px`);
  };

  useEffect(() => {
    if (!isSSR) {
      const isIOS = !!navigator.platform.match(/iPhone|iPod|iPad/);
      if (isIOS) {
        onResize();
        window.addEventListener('resize', onResize);

        return () => {
          window.removeEventListener('resize', onResize);
        };
      }
    }
  }, []);

  return (
    <div className={styles.container}>
      <SEO />
      <div className={styles.background} />
      <div className={styles.inner}>
        <Nav location={location} />
        <main className={styles.content}>{children}</main>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Layout;
