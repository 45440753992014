import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { HoverList, Link, TextWithArrow } from 'components';
import { className } from 'utils';
import styles from './list.module.scss';

const WorkList = ({ columns, link, list, onIndexChange, sort }) => {
  const sortedList = useMemo(() => {
    if (sort) {
      return [...list].sort(sort);
    }
    return list;
  }, [list, sort]);
  const listLinks = useMemo(() => sortedList.map(item => link(item)), [link, sortedList]);
  const listData = useMemo(
    () =>
      sortedList.map(item =>
        columns.reduce(
          (acc, { data, key }) => ({
            ...acc,
            [key]: data(item),
          }),
          {},
        ),
      ),
    [columns, sortedList],
  );

  return (
    <div className={styles.table}>
      <div {...className(styles.row, styles.rowHidden)}>
        {columns.map(({ label, grow }, i) => (
          <div key={i} className={styles.column} style={{ flexGrow: grow || 1 }}>
            {label}
          </div>
        ))}
      </div>
      <HoverList onIndexChange={onIndexChange}>
        {({ listRef, setItemRef, selectedIndex }) => (
          <div ref={listRef} className={styles.body}>
            {listData.map((data, i) => (
              <Link
                key={i}
                ref={setItemRef(i)}
                {...className(
                  styles.row,
                  selectedIndex >= 0 && selectedIndex !== i && styles.rowInactive,
                )}
                to={listLinks[i]}
                newTab>
                {columns.map(({ grow, key, large }) => {
                  const Tag = large ? 'h2' : 'h5';
                  const text = data[key];
                  return (
                    <div key={key} className={styles.column} style={{ flexGrow: grow || 1 }}>
                      <Tag key={`${i}-${key}`} {...className(styles.tag, large && styles.tagBig)}>
                        {large ? (
                          <TextWithArrow className={styles.arrow}>{text}</TextWithArrow>
                        ) : (
                          text
                        )}
                      </Tag>
                    </div>
                  );
                })}
              </Link>
            ))}
          </div>
        )}
      </HoverList>
    </div>
  );
};

WorkList.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.func.isRequired,
      grow: PropTypes.number,
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      large: PropTypes.bool,
    }),
  ).isRequired,
  link: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.object),
  onIndexChange: PropTypes.func,
  sort: PropTypes.func,
};

WorkList.defaultProps = {
  list: [],
};

export default WorkList;
