import React, { forwardRef } from 'react';
import { Link as GatsbyLink, graphql } from 'gatsby';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/display-name
const Link = forwardRef(
  ({ activeClassName, children, className, label, newTab, onClick, tabIndex, title, to }, ref) => {
    const isInternal = /^\/(?!\/)/.test(to);
    const isFile = /\.[0-9a-z]+$/i.test(to);
    const isInternalLink = isInternal && !isFile;

    const props = {
      className,
      onClick,
      tabIndex,
    };

    if (label) {
      props['aria-label'] = label;
    }

    if (title) {
      props.title = title;
    }

    if (!to) {
      return (
        <div ref={ref} {...props}>
          {children}
        </div>
      );
    } else if (isInternalLink) {
      return (
        <GatsbyLink ref={ref} to={to} activeClassName={activeClassName} {...props}>
          {children}
        </GatsbyLink>
      );
    }

    if (newTab || isFile) {
      props.target = '_blank';
    }
    return (
      <a ref={ref} href={to} {...props}>
        {children}
      </a>
    );
  },
);

Link.propTypes = {
  activeClassName: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  to: PropTypes.string.isRequired,
};

Link.defaultProps = {
  activeClassName: '',
  className: '',
  label: '',
  newTab: false,
  tabIndex: 0,
  title: '',
};

export default Link;

export const query = graphql`
  fragment AboutPage on SanityAboutPage {
    _type
  }

  fragment ContactPage on SanityContactPage {
    _type
  }

  fragment Doc on SanityDoc {
    _type
    file {
      name
      asset {
        url
        extension
      }
    }
  }

  fragment HomePage on SanityHomePage {
    _type
  }

  fragment Work on SanityWork {
    _type
    slug {
      current
    }
  }

  fragment WorkPage on SanityWorkPage {
    _type
  }

  fragment Link on SanityLink {
    internalLink {
      _type
      reference {
        ...AboutPage
        ...ContactPage
        ...Doc
        ...HomePage
        ...Work
        ...WorkPage
      }
    }
    label
    text
    url
  }
`;
