import moment from 'moment';

/**
 * Converts a string or Date date to an instance of
 * a moment date.
 *
 * @param {string|Date} date the date to convert
 * @returns {Moment} the moment date
 */
const getMomentInstance = date => moment(date, 'YYYY-MM-DD');

/**
 * Formats the given date to a string.
 *
 * @param {Moment} date the date to format
 * @param {boolean} [hideYear=false] whether to hide
 * the year or not
 * @returns {string} the formatted date
 */
const formatDate = (date, hideYear = false) => date.format(`MMM${hideYear ? '' : ' YYYY'}`);

/**
 * Generates and formats a date range string based
 * on the start and end dates of the given timeline.
 *
 * @param {object} timeline a Sanity timeline object
 * @returns the formatted date range string
 */
const getDateRange = ({ startDate, endDate }) => {
  const start = getMomentInstance(startDate);
  if (endDate === null) {
    return `${formatDate(start)} – Present`;
  }
  const end = getMomentInstance(endDate);
  return `${formatDate(start, start.year() === end.year())} – ${formatDate(end)}`;
};

export default getDateRange;
