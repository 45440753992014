import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'components';

const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;

wrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
  props: PropTypes.object.isRequired,
};

export default wrapPageElement;
