import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

const SEO = ({ description, image, path, title, twitterCard }) => {
  const {
    site: { seo, siteName, siteUrl, twitterHandle },
  } = useStaticQuery(graphql`
    query SiteQuery {
      site: sanitySite {
        seo {
          ...SEO
        }
        siteName
        siteUrl
        twitterHandle
      }
    }
  `);

  const metaTitle = useMemo(
    () => (!title || title === seo.title ? seo.title : `${title} | ${seo.title}`),
    [title, seo.title],
  );
  const metaDescription = useMemo(() => description || seo.description, [
    description,
    seo.description,
  ]);
  const metaUrl = useMemo(() => `${siteUrl}${path}`, [path, siteUrl]);

  const metaImage = useMemo(() => image || seo.image, [image, seo.image]);
  const metaImageUrl = useMemo(() => (metaImage && metaImage.asset ? metaImage.asset.url : ''), [
    metaImage,
  ]);
  const metaImageAlt = useMemo(() => (metaImage && metaImage.asset ? metaImage.alt : ''), [
    metaImage,
  ]);
  const metaTwitterCard = useMemo(() => (image ? twitterCard : seo.twitterCard), [
    image,
    twitterCard,
    seo.twitterCard,
  ]);

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        /* OG Meta */
        {
          property: 'og:locale',
          content: 'en_US',
        },
        {
          property: 'og:site_name',
          content: siteName,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        /* Twitter Meta */
        {
          property: 'twitter:card',
          content: metaTwitterCard,
        },
        {
          property: 'twitter:creator',
          content: twitterHandle,
        },
        {
          property: 'twitter:site',
          content: twitterHandle,
        },
        /* OG and Twitter Meta */
        ...['og', 'twitter'].flatMap(type => [
          {
            property: `${type}:description`,
            content: metaDescription,
          },
          {
            property: `${type}:image`,
            content: metaImageUrl,
          },
          {
            property: `${type}:image:alt`,
            content: metaImageAlt,
          },
          {
            property: `${type}:title`,
            content: metaTitle,
          },
          {
            property: `${type}:url`,
            content: metaUrl,
          },
        ]),
      ]}
    />
  );
};

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.shape({
    asset: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  path: PropTypes.string,
  title: PropTypes.string,
  twitterCard: PropTypes.string,
};

SEO.defaultProps = {
  path: '/',
};

export default SEO;

export const query = graphql`
  fragment SEO on SanitySeo {
    title
    description
    image {
      alt
      asset {
        url
      }
    }
    twitterCard
  }
`;
