import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import styles from './block.module.scss';

const Block = ({ children, className: customClassName, forceGrow, grow, padded, title }) => (
  <div
    {...className(
      styles.block,
      (forceGrow || grow) && styles.blockGrow,
      forceGrow && styles.blockForceGrow,
    )}>
    <div {...className(styles.blockInner, padded && styles.blockInnerPadded, customClassName)}>
      {title && <h3 className={styles.title}>{title}</h3>}
      {children}
    </div>
  </div>
);

Block.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  forceGrow: PropTypes.bool,
  grow: PropTypes.bool,
  padded: PropTypes.bool,
  title: PropTypes.string,
};

Block.defaultProps = {
  className: '',
  forceGrow: false,
  grow: false,
  padded: false,
};

export default Block;
