import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import styles from './arrow.module.scss';

const Arrow = ({ external, small }) => (
  <span className={styles.container}>
    <svg
      {...className(external && styles.external, small && styles.small)}
      viewBox="0 0 17 12"
      xmlns="http://www.w3.org/2000/svg">
      <polyline
        className={styles.arrow}
        transform="translate(9.631785, 6) rotate(-315) translate(-9.631785, -8)"
        points="5.78563103 4.15384615 13.4779387 4.15384615 13.4779387 11.8461538"
      />
      <path
        className={styles.arrow}
        d="M13,3 L3,13"
        transform="translate(8, 6) rotate(-315) translate(-8, -8)"
      />
    </svg>
  </span>
);

Arrow.propTypes = {
  external: PropTypes.bool,
  small: PropTypes.bool,
};

Arrow.defaultProps = {
  external: false,
  small: false,
};

export default Arrow;
