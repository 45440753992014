import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Block, Column, Content, HoverList, Link, Row, SEO, TextWithArrow } from 'components';
import { className, getLinkUrl } from 'utils';
import styles from './styles/contact.module.scss';

const Contact = ({
  data: {
    page: { availBody, availTitle, links, linksTitle, seo },
  },
}) => (
  <Column>
    <SEO {...seo} path="/contact" />
    <Row grow>
      <Block className={styles.linkBlock} forceGrow padded title={linksTitle}>
        <HoverList>
          {({ listRef, setItemRef, selectedIndex }) => (
            <ul ref={listRef} className={styles.linkList}>
              {links.map(({ link: { internalLink, label, text, url }, splitBy, type }, i) => (
                <li
                  key={i}
                  {...className(
                    styles.linkItem,
                    selectedIndex >= 0 && selectedIndex !== i && styles.linkItemInactive,
                  )}>
                  {type}
                  <span className={styles.hidden}>: </span>
                  <Link
                    ref={setItemRef(i)}
                    className={styles.link}
                    to={getLinkUrl(internalLink, url)}
                    label={label}
                    title={label}
                    newTab>
                    <TextWithArrow className={styles.arrow} splitBy={splitBy}>
                      {text}
                    </TextWithArrow>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </HoverList>
      </Block>
    </Row>
    <Block padded title={availTitle}>
      <Content className={styles.availability} blocks={availBody} />
    </Block>
  </Column>
);

Contact.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
};

export default Contact;

export const query = graphql`
  query contactPageQuery {
    page: sanityContactPage {
      seo {
        ...SEO
      }
      linksTitle
      links {
        link {
          ...Link
        }
        splitBy
        type
      }
      availTitle
      availBody: _rawAvailBody
    }
  }
`;
