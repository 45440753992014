import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const HoverList = ({ children, noFocus, onIndexChange }) => {
  const listRef = useRef();
  const itemRefs = useRef([]);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  /**
   * Returns a ref setter function to add a ref of an element at the
   * given index in the items ref array.
   *
   * @param {number} i the index of the element
   * @returns {function} the ref setter function to pass as a React
   * component's `ref` prop
   */
  const setItemRef = i => el => {
    itemRefs.current[i] = el;
  };

  /**
   * Returns a callback function for an event listener that will set the
   * selected index to the given one.
   *
   * @param {number} i the index to set the selected index to
   * @returns the callback function to pass to the event listener
   */
  const onHover = i => () => {
    setSelectedIndex(i);
  };

  /**
   * Resets the selected index, on blur of an active element. It also checks if
   * any of the other items are the active element and sets that to the selected
   * index to try and set that as the selected index instead.
   */
  const onBlur = () => {
    let activeIndex = -1;
    if (!noFocus) {
      activeIndex = itemRefs.current.findIndex(ref => ref === document.activeElement);
    }
    setSelectedIndex(activeIndex);
  };

  useEffect(() => {
    itemRefs.current.forEach((ref, i) => {
      ref.addEventListener('mouseover', onHover(i));
      if (!noFocus) {
        ref.addEventListener('focus', onHover(i));
        ref.addEventListener('blur', onBlur);
      }
    });
    listRef.current.addEventListener('mouseleave', onBlur);

    return () => {
      itemRefs.current.forEach((ref, i) => {
        if (ref) {
          ref.removeEventListener('mouseover', onHover(i));
          if (!noFocus) {
            ref.removeEventListener('focus', onHover(i));
            ref.removeEventListener('blur', onBlur);
          }
        }
      });
      if (listRef.current) {
        listRef.current.removeEventListener('mouseleave', onBlur);
      }
    };
  }, []);

  useEffect(() => {
    if (onIndexChange) {
      onIndexChange(selectedIndex);
    }
  }, [selectedIndex]);

  return children({ listRef, setItemRef, selectedIndex });
};

HoverList.propTypes = {
  children: PropTypes.func.isRequired,
  noFocus: PropTypes.bool,
  onIndexChange: PropTypes.func,
};

HoverList.defaultProps = {
  noFocus: false,
};

export default HoverList;
