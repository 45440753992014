import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BlockContent from '@sanity/block-content-to-react';
import baseMarks from './marks';

const Content = ({ blocks, className, marks, types }) => {
  const serializers = useMemo(
    () => ({
      marks: {
        ...baseMarks,
        ...marks,
      },
      types,
    }),
    [marks, types],
  );

  if (!blocks || !blocks.length) {
    return null;
  }

  return (
    <BlockContent
      className={className}
      blocks={blocks}
      serializers={serializers}
      renderContainerOnSingleChild
    />
  );
};

Content.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  marks: PropTypes.object,
  types: PropTypes.object,
};

Content.defaultProps = {
  blocks: [],
  className: '',
  marks: {},
  types: {},
};

export default Content;
