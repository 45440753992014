import React from 'react';
import styles from './play.module.scss';

const Play = () => (
  <span className={styles.container}>
    <svg viewBox="-1 -1 14 17" xmlns="http://www.w3.org/2000/svg">
      <polygon
        className={styles.play}
        transform="translate(6, 7.5) rotate(-270) translate(-6, -7.5)"
        points="6 2 13 13 -1 13"
      />
    </svg>
  </span>
);

export default Play;
