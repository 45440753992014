import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import imagesLoaded from 'imagesloaded';
import styles from './imageLoader.module.scss';

const ImageLoader = ({ children, src }) => {
  const imgRef = useRef();
  const [source, setSource] = useState(src);
  const [isLoaded, setIsLoaded] = useState(false);

  const onLoad = src => () => {
    setSource(src);
    setIsLoaded(true);
  };

  useEffect(() => {
    if (imgRef.current && src) {
      imagesLoaded(imgRef.current, onLoad(src));
    }
  }, [imgRef.current, src]);

  return (
    <Fragment>
      <img ref={imgRef} tabIndex={-1} className={styles.hidden} alt={`Loading ${src}`} />
      {children && children(isLoaded, source)}
    </Fragment>
  );
};

ImageLoader.propTypes = {
  children: PropTypes.func,
  src: PropTypes.string.isRequired,
};

export default ImageLoader;
