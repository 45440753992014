import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import styles from './row.module.scss';

const Row = ({ children, grow }) => (
  <div {...className(styles.container, grow && styles.grow)}>{children}</div>
);

Row.propTypes = {
  children: PropTypes.node,
  grow: PropTypes.bool,
};

Row.defaultProps = {
  grow: false,
};

export default Row;
