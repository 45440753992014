import React from 'react';
import PropTypes from 'prop-types';
import styles from './strong.module.scss';

const Strong = ({ children }) => <span className={styles.strong}>{children}</span>;

Strong.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Strong;
