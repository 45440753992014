import React from 'react';
import PropTypes from 'prop-types';
import { Content } from 'components';
import types from './types';
import styles from './body.module.scss';

const WorkBody = ({ body }) => <Content className={styles.container} blocks={body} types={types} />;

WorkBody.propTypes = {
  body: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default WorkBody;
