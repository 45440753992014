import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'swiper';
import { Content, Link } from 'components';
import Image from 'components/Work/Body/types/Image';
import { Arrow } from 'svgs';
import { className } from 'utils';
import styles from './slideshow.module.scss';

const SWIPER_CLASS = {
  CONTAINER: 'swiper-container',
  WRAPPER: 'swiper-wrapper',
  SLIDE: 'swiper-slide',
};

const Slideshow = ({ node: { slides } }) => {
  const swiperContainerRef = useRef();
  const swiperRef = useRef(null);

  const [index, setIndex] = useState(0);
  const lastIndex = useMemo(() => (slides && slides.length ? slides.length - 1 : 0), [slides]);

  const currentSlide = useMemo(() => {
    if (slides && slides.length) {
      const slide = slides[index];
      if (slide.title || (slide.description && slide.description.length)) {
        return slide;
      }
    }
    return null;
  }, [index, slides]);

  /**
   * Either advances or goes back one slide in the slideshow.
   *
   * @param {boolean} [forward=true] whether to move forward or not
   */
  const changeSlide = (forward = true) => () => {
    if (swiperRef.current) {
      swiperRef.current[forward ? 'slideNext' : 'slidePrev']();
    }
  };

  /**
   * Initializes the swiper instance to create the slideshow.
   */
  const initSwiper = () => {
    const swiper = new Swiper(swiperContainerRef.current, {
      centeredSlides: true,
      initialSlide: index,
      slidesPerView: 'auto',
      spaceBetween: parseInt(styles.varSlideSpacing),
    });
    swiperRef.current = swiper;

    swiper.on('slideChange', () => {
      setIndex(swiperRef.current.activeIndex);
    });
  };

  useEffect(() => {
    if (slides && slides.length > 1) {
      initSwiper();
    }
    return () => {
      if (swiperRef.current) {
        swiperRef.current.destroy();
        swiperRef.current = null;
      }
    };
  }, []);

  if (!slides || !slides.length) {
    return null;
  } else if (slides.length === 1) {
    const slide = slides[0];
    const node = {
      alt: slide.alt,
      asset: slide.image && slide.image.asset,
      caption: slide.title,
    };
    return <Image node={node} />;
  }
  return (
    <section className={styles.container}>
      <div className={styles.pagination}>
        <p className={styles.slideNumber}>
          <span className={styles.current}>{index + 1}</span>
          {'/'}
          <span className={styles.total}>{slides.length}</span>
        </p>
        <div className={styles.controls}>
          <button
            {...className(styles.btn, styles.btnPrev)}
            disabled={index === 0}
            title="Previous"
            onClick={changeSlide(false)}>
            <Arrow />
          </button>
          <button
            className={styles.btn}
            disabled={index === lastIndex}
            title="Next"
            onClick={changeSlide()}>
            <Arrow />
          </button>
        </div>
      </div>
      <div ref={swiperContainerRef} {...className(SWIPER_CLASS.CONTAINER, styles.swiper)}>
        <div {...className(SWIPER_CLASS.WRAPPER, styles.swiperWrapper)}>
          {slides.map(({ alt, image: { asset: { url } } }, i) => (
            <div
              key={i}
              {...className(SWIPER_CLASS.SLIDE, styles.slide, i !== index && styles.slideInactive)}>
              <Link className={styles.slideLink} to={url} newTab tabIndex={i === index ? 0 : -1}>
                <img className={styles.slideImage} src={url} alt={alt} />
              </Link>
            </div>
          ))}
        </div>
      </div>
      {currentSlide && (
        <div className={styles.caption}>
          <div className={styles.captionWrapper}>
            {currentSlide.title && <h6 className={styles.title}>{currentSlide.title}</h6>}
            {currentSlide.description && !!currentSlide.description.length && (
              <Content
                {...className(styles.description, !currentSlide.title && styles.descriptionOnly)}
                blocks={currentSlide.description}
              />
            )}
          </div>
        </div>
      )}
    </section>
  );
};

Slideshow.propTypes = {
  node: PropTypes.shape({
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        alt: PropTypes.string.isRequired,
        description: PropTypes.array,
        image: PropTypes.shape({
          asset: PropTypes.object.isRequired,
        }),
        title: PropTypes.string,
      }),
    ),
  }),
};

export default Slideshow;
