import React from 'react';
import styles from './defs.module.scss';

const COLORS = [styles.varColorPink, styles.varColorBlue, styles.varColorYellow];

const Defs = () => (
  <svg className={styles.svg} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient
        id="color-gradient"
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        gradientTransform="rotate(45)">
        {COLORS.map((color, i) => {
          let values = [...COLORS];
          if (i - 1 >= 0) {
            values = [...values.slice(i), ...values.slice(0, i)];
          }
          values.push(color);
          return (
            <stop key={i} offset={`${(i / 2) * 100}%`} stopColor={color}>
              <animate
                attributeName="stop-color"
                values={values.join('; ')}
                dur="4s"
                repeatCount="indefinite"
              />
            </stop>
          );
        })}
      </linearGradient>
    </defs>
  </svg>
);

export default Defs;
