import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { INTERNAL_LINK_MAP } from 'utils/constants';

const InternalLink = ({
  children,
  mark: {
    reference: { _type, ...reference },
  },
}) => {
  const to = useMemo(() => INTERNAL_LINK_MAP[_type](reference), [_type, reference]);
  return <Link to={to}>{children}</Link>;
};

InternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  mark: PropTypes.shape({
    reference: PropTypes.shape({
      _type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default InternalLink;
