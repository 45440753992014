import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { ArrowLink, Block, Column, Content, ImageLoader, Row, SEO, HoverList } from 'components';
import { className, getDateRange, getLinkUrl } from 'utils';
import { INTERNAL_LINK_MAP, SANITY_TYPES } from 'utils/constants';
import styles from './styles/about.module.scss';

const About = ({
  data: {
    jobs,
    page: {
      creditsBody,
      creditsTitle,
      heroImage,
      intro,
      introTitle,
      jobsTitle,
      likes,
      likesTitle,
      links,
      linksTitle,
      seo,
    },
    work,
  },
}) => {
  /**
   * Memoized value for the jobs array that includes the related work
   * that each job has.
   */
  const fullJobs = useMemo(() => {
    const workMap = work.nodes.reduce((acc, { origin, primaryLink, slug, title }) => {
      if (origin && origin.__typename === SANITY_TYPES.JOB) {
        acc[origin.id] = acc[origin.id] || [];
        acc[origin.id].push({
          newTab: !!primaryLink,
          slug: slug.current,
          title,
          to: primaryLink || INTERNAL_LINK_MAP.work({ slug }),
        });
      }
      return acc;
    }, {});

    return jobs.nodes.map(({ id, ...job }) => ({
      ...job,
      id,
      relatedWork: workMap[id] || [],
    }));
  }, [jobs, work]);

  return (
    <Column>
      <SEO {...seo} path="/about" />
      <Row>
        <div className={styles.hero}>
          <ImageLoader src={heroImage.asset.fluid.src}>
            {(isLoaded, src) => (
              <div
                {...className(styles.photo, !isLoaded && styles.photoHidden)}
                style={{ backgroundImage: `url(${src})` }}
              />
            )}
          </ImageLoader>
          <Block className={styles.aboutBlock} grow padded>
            <div className={styles.about}>
              <h3 className={styles.aboutTitle}>{introTitle}</h3>
              <p className={styles.aboutDesc}>{intro}</p>
            </div>
          </Block>
        </div>
      </Row>
      <Row>
        <Block grow padded title={jobsTitle}>
          <div className={styles.work}>
            <ul className={styles.workList}>
              {fullJobs.map(({ company, description, id, relatedWork, role, timeline }) => (
                <li key={id} className={styles.workItem}>
                  <p className={styles.workDuration}>{getDateRange(timeline)}</p>
                  <h5 className={styles.workTitle}>
                    {role}
                    <span className={styles.hidden}> at </span>
                    <span className={styles.workLocation}>{company.name}</span>
                  </h5>
                  <Content className={styles.workDesc} blocks={description} />
                  {relatedWork.length > 0 && (
                    <div className={styles.relatedWork}>
                      <p className={styles.relatedWorkLabel}>Related Work</p>
                      <HoverList>
                        {({ listRef, setItemRef, selectedIndex }) => (
                          <ul ref={listRef} className={styles.relatedWorkList}>
                            {relatedWork.map(({ newTab, slug, title, to }, i) => (
                              <li
                                key={slug}
                                {...className(
                                  styles.relatedWorkItem,
                                  selectedIndex >= 0 &&
                                    selectedIndex !== i &&
                                    styles.relatedWorkItemInactive,
                                )}>
                                <ArrowLink
                                  ref={setItemRef(i)}
                                  className={styles.link}
                                  newTab={newTab}
                                  to={to}
                                  text={title}
                                  small
                                />
                              </li>
                            ))}
                          </ul>
                        )}
                      </HoverList>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </Block>
      </Row>
      <Row grow>
        <Block grow padded title={likesTitle}>
          <ul className={styles.likeList}>
            {likes.map(({ caption, name }, i) => (
              <li key={i} className={styles.likeItem}>
                {name}
                <Content className={styles.likeCaption} blocks={caption} />
              </li>
            ))}
          </ul>
        </Block>
        <Block grow padded title={linksTitle}>
          <HoverList>
            {({ listRef, setItemRef, selectedIndex }) => (
              <ul ref={listRef} className={styles.linkList}>
                {links.map(({ internalLink, text, url }, i) => (
                  <li
                    key={i}
                    {...className(
                      styles.linkItem,
                      selectedIndex >= 0 && selectedIndex !== i && styles.linkItemInactive,
                    )}>
                    <ArrowLink
                      ref={setItemRef(i)}
                      className={styles.link}
                      to={getLinkUrl(internalLink, url)}
                      text={text}
                      newTab
                    />
                  </li>
                ))}
              </ul>
            )}
          </HoverList>
        </Block>
      </Row>
      <Row>
        <Block grow padded title={creditsTitle}>
          <Content className={styles.credits} blocks={creditsBody} />
        </Block>
      </Row>
    </Column>
  );
};

About.propTypes = {
  data: PropTypes.shape({
    jobs: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    work: PropTypes.object.isRequired,
  }).isRequired,
};

export default About;

export const query = graphql`
  query aboutPageQuery {
    page: sanityAboutPage {
      seo {
        ...SEO
      }
      creditsBody: _rawCreditsBody
      creditsTitle
      heroImage {
        asset {
          fluid(maxWidth: 3000) {
            src
          }
        }
      }
      intro
      introTitle
      jobsTitle
      likes: _rawLikes
      likesTitle
      links {
        ...Link
      }
      linksTitle
    }

    jobs: allSanityJob(sort: { fields: timeline___startDate, order: DESC }) {
      nodes {
        company {
          name
        }
        description: _rawDescription
        id
        role
        timeline {
          startDate
          endDate
        }
      }
    }

    work: allSanityWork(sort: { fields: timeline___endDate, order: DESC }) {
      nodes {
        origin {
          ... on SanityJob {
            id
          }
        }
        primaryLink
        slug {
          current
        }
        title
      }
    }
  }
`;
