import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import styles from './blockquote.module.scss';

const Blockquote = ({
  node: {
    quote,
    speaker: { name, websiteUrl },
  },
}) => {
  const quoteBlocks = useMemo(() => quote.trim().split('\n'), [quote]);
  return (
    <blockquote className={styles.container}>
      <div className={styles.quoteWrapper}>
        {quoteBlocks.map((quote, i) => (
          <p key={i} className={styles.quote}>
            {quote}
          </p>
        ))}
      </div>
      <footer className={styles.speaker}>
        {websiteUrl ? (
          <Link to={websiteUrl} newTab>
            {name}
          </Link>
        ) : (
          name
        )}
      </footer>
    </blockquote>
  );
};

Blockquote.propTypes = {
  node: PropTypes.shape({
    quote: PropTypes.string.isRequired,
    speaker: PropTypes.shape({
      name: PropTypes.string.isRequired,
      websiteUrl: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Blockquote;
