import React from 'react';
import PropTypes from 'prop-types';
import styles from './column.module.scss';

const Column = ({ children }) => <div className={styles.container}>{children}</div>;

Column.propTypes = {
  children: PropTypes.node,
};

export default Column;
