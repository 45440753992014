import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';

const ExternalLink = ({ children, mark: { url } }) => (
  <Link to={url} newTab>
    {children}
  </Link>
);

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  mark: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default ExternalLink;
