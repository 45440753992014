import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { ArrowLink, Block, BlockPreview, Column, HoverList, Link, Row, SEO } from 'components';
import { Arrow } from 'svgs';
import { className, getAsset, getEndDate } from 'utils';
import { INTERNAL_LINK_MAP } from 'utils/constants';
import styles from './styles/home.module.scss';

const Home = ({
  data: {
    page: { about, aboutLink, contactLink, featuredWork, seo, workTitle, workLink },
  },
}) => {
  const [selectedWork, setSelectedWork] = useState(-1);

  const work = useMemo(
    () =>
      featuredWork.map(({ primaryLink, slug, timeline, title }) => ({
        newTab: !!primaryLink,
        title,
        to: primaryLink || INTERNAL_LINK_MAP.work({ slug }),
        year: getEndDate(timeline).getFullYear(),
      })),
    [],
  );

  const workImages = useMemo(() => featuredWork.map(({ preview }) => getAsset(preview)), []);

  return (
    <Column>
      <SEO {...seo} path="/" />
      <Row>
        <Block className={styles.about} grow padded>
          <h4 className={styles.aboutText}>{about}</h4>
          <ArrowLink className={styles.aboutLink} to="/about" text={aboutLink} />
        </Block>
      </Row>
      <Row grow>
        <Block className={styles.work} grow padded title={workTitle}>
          <BlockPreview images={workImages} index={selectedWork}>
            <HoverList onIndexChange={setSelectedWork}>
              {({ listRef, setItemRef, selectedIndex }) => (
                <ul className={styles.workList} ref={listRef}>
                  {work.map(({ newTab, title, to, year }, i) => (
                    <li key={i} className={styles.workItem}>
                      <ArrowLink
                        ref={setItemRef(i)}
                        {...className(
                          styles.workLink,
                          selectedIndex >= 0 && i !== selectedIndex && styles.workLinkInactive,
                          i === selectedIndex && styles.workLinkActive,
                        )}
                        large
                        newTab={newTab}
                        to={to}
                        text={title}>
                        <p className={styles.workYear}>{year}</p>
                      </ArrowLink>
                    </li>
                  ))}
                </ul>
              )}
            </HoverList>
          </BlockPreview>
          <ArrowLink className={styles.seeAllLink} to="/work" text={workLink} />
        </Block>
        <Block grow>
          <Link className={styles.contact} to="/contact">
            <div className={styles.contactInner}>
              <h1 className={styles.contactTitle}>{contactLink}</h1>
              <div className={styles.contactArrow}>
                <Arrow external />
              </div>
            </div>
          </Link>
        </Block>
      </Row>
    </Column>
  );
};

Home.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
};

export default Home;

export const query = graphql`
  query homePageQuery {
    page: sanityHomePage {
      seo {
        ...SEO
      }
      about
      aboutLink
      workTitle
      featuredWork {
        preview {
          asset {
            url
          }
        }
        primaryLink
        slug {
          current
        }
        timeline {
          endDate
        }
        title
      }
      workLink
      contactLink
    }
  }
`;
