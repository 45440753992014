export const INTERNAL_LINK_MAP = {
  aboutPage: () => '/about',
  contactPage: () => '/contact',
  doc: ({ file, _rawFile }) => {
    const dataFile = _rawFile || file;
    return `/files/${dataFile.name}.${dataFile.asset.extension}`;
  },
  homePage: () => '/',
  work: ({ slug, _rawSlug }) => `/work/${(_rawSlug || slug).current}`,
  workPage: () => '/work',
};

export const KEY_CODES = Object.freeze({
  ESCAPE: 27,
});

export const SANITY_TYPES = Object.freeze({
  COURSE: 'SanityCourse',
  JOB: 'SanityJob',
});
