import React from 'react';
import styles from './caution.module.scss';

const Caution = () => (
  <div className={styles.container}>
    <svg viewBox="0 0 66 58" xmlns="http://www.w3.org/2000/svg">
      <path
        className={styles.exclamation}
        d="M30.4028577,45.8392297 L30.4028577,41.8392297 L35.4028577,41.8392297 L35.4028577,45.8392297 L30.4028577,45.8392297 Z M31.2305604,36.8392297 L30.4028577,23.8609073 L30.4028577,18.8392297 L35.4028577,18.8392297 L35.4028577,23.8609073 L34.575155,36.8392297 L31.2305604,36.8392297 Z"
      />
      <path
        className={styles.triangle}
        d="M64.7369124,45.9805115 C66.8680236,49.5323635 65.7162856,54.1393154 62.1644336,56.2704266 C60.9988181,56.9697959 59.665045,57.3392297 58.3057155,57.3392297 L7.5,57.3392297 C3.35786438,57.3392297 -1.13686838e-13,53.9813653 -1.13686838e-13,49.8392297 C-1.13686838e-13,48.4799001 0.36943375,47.146127 1.06880306,45.9805115 L26.4716608,3.64241529 C28.602772,0.0905632999 33.2097239,-1.06117468 36.7615759,1.06993652 C37.8171979,1.70330974 38.7006815,2.58679326 39.3340547,3.64241529 L64.7369124,45.9805115 Z M35.2180886,3.64241529 C33.0869774,2.36374858 30.3228063,3.05479136 29.0441396,5.18590256 L3.64128183,47.5239988 C3.22166025,48.2233681 3,49.0236319 3,49.8392297 C3,52.324511 5.01471863,54.3392297 7.5,54.3392297 L58.3057155,54.3392297 C59.1213132,54.3392297 59.921577,54.1175694 60.6209464,53.6979478 C62.7520575,52.4192811 63.4431003,49.65511 62.1644336,47.5239988 L36.7615759,5.18590256 C36.381552,4.55252934 35.8514618,4.02243923 35.2180886,3.64241529 Z"
      />
    </svg>
  </div>
);

export default Caution;
