import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { ArrowLink, Block, Column, Content, Row, SEO } from 'components';
import styles from './styles/404.module.scss';

const NotFound = ({
  data: {
    page: { body, homeLinkText, illustration, seo, title },
  },
  location,
}) => (
  <Column>
    <SEO {...seo} path={location.pathname} />
    <Row grow>
      <Block className={styles.block} forceGrow padded>
        <div className={styles.text}>
          <h2 className={styles.title}>{title}</h2>
          <Content className={styles.body} blocks={body} />
          <ArrowLink className={styles.link} to="/" text={homeLinkText} />
        </div>
        <div className={styles.illusWrapper}>
          <Img
            className={styles.illus}
            fluid={illustration.asset.fluid}
            alt={illustration.alt}
            backgroundColor={styles.varBgColor}
          />
        </div>
      </Block>
    </Row>
  </Column>
);

NotFound.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default NotFound;

export const query = graphql`
  query notFoundPageQuery {
    page: sanityNotFoundPage {
      seo {
        ...SEO
      }
      body: _rawBody
      homeLinkText
      illustration {
        alt
        asset {
          fluid(maxWidth: 250) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      title
    }
  }
`;
