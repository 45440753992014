import React, { useEffect, useMemo, useRef, useState, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { HoverList, ImageLoader, Link } from 'components';
import { useDetectSSR, useStateRef } from 'hooks';
import { Arrow, Logo, Play } from 'svgs';
import { api, className, getLinkUrl } from 'utils';
import { KEY_CODES } from 'utils/constants';
import styles from './nav.module.scss';

const MOBILE_BP = parseInt(styles.varMobileBreakpoint);
const NUM_PAGE_CHANGES_TO_FETCH = 5;

const Nav = ({ location }) => {
  const {
    nav: { links, spotifyLabel, spotifyLinkTitle },
  } = useStaticQuery(graphql`
    query navQuery {
      nav: sanityNav {
        links {
          activeRegEx
          link {
            ...Link
          }
        }
        spotifyLabel
        spotifyLinkTitle
      }
    }
  `);

  const mobileMenuRef = useRef();

  const isSSR = useDetectSSR();
  const [isNavOpen, setIsNavOpen, isNavOpenRef] = useStateRef(false);

  const [nowPlaying, setNowPlaying] = useState(null);
  const [nowPlayingError, setNowPlayingError] = useState(false);
  const [locationChangeCount, setLocationChangeCount] = useState(0);

  const menuLabel = useMemo(() => {
    if (isNavOpen) {
      return 'Close Menu';
    }
    return 'Open Menu';
  }, [isNavOpen]);

  const playerLinkLabel = useMemo(() => {
    if (nowPlayingError) {
      return '';
    } else if (nowPlaying) {
      return spotifyLinkTitle
        .replace('%SONG%', nowPlaying.name)
        .replace('%ARTIST%', nowPlaying.artist);
    }
    return 'Loading...';
  }, [nowPlaying, setNowPlayingError]);

  const playerAlbum = useMemo(
    () => (
      <ImageLoader src={nowPlaying ? nowPlaying.cover : ''}>
        {(isLoaded, src) => (
          <div
            {...className(styles.album, !isLoaded && styles.albumHidden)}
            style={{ backgroundImage: `url(${src})` }}
          />
        )}
      </ImageLoader>
    ),
    [nowPlaying],
  );

  const songMeta = useMemo(
    () => (
      <div className={styles.songMeta}>
        {nowPlayingError ? (
          <p className={styles.error}>Nothing playing...</p>
        ) : (
          <Fragment>
            <p className={styles.label}>{spotifyLabel}</p>
            <p className={styles.title}>{nowPlaying ? nowPlaying.name : 'Loading...'}</p>
            {nowPlaying && <p className={styles.artist}>{nowPlaying.artist}</p>}
          </Fragment>
        )}
      </div>
    ),
    [nowPlaying, nowPlayingError],
  );

  /**
   * Gets the currently playing song on my spotify.
   */
  const getNowPlaying = async () => {
    try {
      const nowPlaying = await api.nowPlaying.get();
      setNowPlaying(nowPlaying);
      setNowPlayingError(false);
    } catch {
      setNowPlayingError(true);
    }
  };

  /**
   * Toggles the visibility of the nav, as well as the body locking
   * mechanism to prevent scroll when nav is open.
   *
   * @param {boolean} [toggle] the option for the nav to toggle. If no
   * value is provided, the toggle will automatically do the reverse
   * of the current `isNavOpen` value.
   */
  const toggleNav = toggle => {
    let nextIsNavOpen = toggle;
    if (toggle === undefined) {
      nextIsNavOpen = !isNavOpenRef.current;
    }

    setIsNavOpen(nextIsNavOpen);
    if (nextIsNavOpen) {
      disableBodyScroll(mobileMenuRef.current);
    } else {
      enableBodyScroll(mobileMenuRef.current);
    }
  };

  /**
   * Key event listener that closes the nav when the escape
   * button is clicked.
   *
   * @param {Object} evt the key event
   */
  const onKeyDown = ({ keyCode }) => {
    if (keyCode === KEY_CODES.ESCAPE && isNavOpenRef.current) {
      toggleNav(false);
    }
  };

  /**
   * Resize listener that checks if the window is desktop size,
   * and, if so, closes the mobile nav if open.
   */
  const onWindowResize = () => {
    if (window.innerWidth >= MOBILE_BP && isNavOpenRef.current) {
      toggleNav(false);
    }
  };

  useEffect(() => {
    if (!isSSR) {
      window.addEventListener('keydown', onKeyDown);
      window.addEventListener('resize', onWindowResize);

      return () => {
        window.removeEventListener('keydown', onKeyDown);
        window.removeEventListener('resize', onWindowResize);
        clearAllBodyScrollLocks();
      };
    }
  }, []);

  useEffect(() => {
    if (!isSSR) {
      if (locationChangeCount % NUM_PAGE_CHANGES_TO_FETCH === 0) {
        getNowPlaying();
      }
      setLocationChangeCount(idx => idx + 1);
    }
  }, [location.pathname]);

  return (
    <Location>
      {({ location }) => (
        <nav className={styles.container}>
          <header className={styles.inner}>
            <Link
              {...className(styles.logo, isNavOpen && styles.logoDark)}
              to="/"
              label="Home"
              onClick={() => toggleNav(false)}>
              <Logo />
              <Logo className={styles.logoGradient} />
              <span className={styles.hidden}>Josh Pensky</span>
            </Link>
            <HoverList>
              {({ listRef, setItemRef, selectedIndex }) => (
                <div className={styles.links} ref={listRef}>
                  {links.map(({ activeRegEx, link: { internalLink, text, url } }, i) => (
                    <Link
                      key={i}
                      {...className(
                        styles.link,
                        selectedIndex >= 0 && selectedIndex !== i && styles.linkInactive,
                        activeRegEx &&
                          RegExp(activeRegEx, 'g').test(location.pathname) &&
                          styles.linkActive,
                      )}
                      activeClassName={styles.linkActive}
                      to={getLinkUrl(internalLink, url)}
                      ref={setItemRef(i)}>
                      <span className={styles.linkArrow}>
                        <Arrow />
                      </span>
                      {text}
                    </Link>
                  ))}
                </div>
              )}
            </HoverList>
            <Link
              {...className(styles.player, !nowPlaying && styles.playerLoading)}
              to={nowPlaying ? nowPlaying.link : ''}
              label={playerLinkLabel}
              newTab>
              <span className={styles.hidden}>{playerLinkLabel}</span>
              {playerAlbum}
              <div className={styles.playerPopUp}>{songMeta}</div>
              <div className={styles.play}>
                <Play />
              </div>
            </Link>
            <button className={styles.menuBtn} aria-label={menuLabel} onClick={() => toggleNav()}>
              <div {...className(styles.menuIcon, isNavOpen && styles.menuIconClosed)} />
            </button>
          </header>
          <div {...className(styles.mobileMenu, !isNavOpen && styles.mobileMenuHidden)}>
            <div className={styles.mobileMenuInner} ref={mobileMenuRef}>
              <HoverList>
                {({ listRef, setItemRef, selectedIndex }) => (
                  <div className={styles.links} ref={listRef}>
                    {links.map(({ link: { internalLink, text, url } }, i) => (
                      <Link
                        key={i}
                        ref={setItemRef(i)}
                        {...className(
                          styles.link,
                          selectedIndex >= 0 && selectedIndex !== i && styles.linkInactive,
                        )}
                        to={getLinkUrl(internalLink, url)}
                        tabIndex={isNavOpen ? 0 : -1}
                        onClick={() => toggleNav(false)}>
                        <h4 className={styles.linkText}>{text}</h4>
                      </Link>
                    ))}
                  </div>
                )}
              </HoverList>
            </div>
            <div className={styles.mobilePlayer}>
              <Link
                className={styles.mobilePlayerInner}
                to={nowPlaying ? nowPlaying.link : ''}
                tabIndex={isNavOpen ? 0 : -1}
                label={playerLinkLabel}
                newTab>
                <span className={styles.hidden}>{playerLinkLabel}</span>
                {playerAlbum}
                {songMeta}
              </Link>
            </div>
          </div>
        </nav>
      )}
    </Location>
  );
};

Nav.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Nav;
