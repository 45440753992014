export default {
  get: async () => {
    const res = await fetch('/.netlify/functions/now-playing');
    const track = await res.json();
    if (track.spotify) {
      const { name, artists, album, link } = track.spotify;
      return {
        name,
        artist: artists.map(({ name }) => name).join(', '),
        cover: album.covers.normal,
        link,
      };
    }
    return {
      name: track.name,
      artist: track.artist,
      cover: '',
      link: '',
    };
  },
};
