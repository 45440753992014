import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Block, BlockPreview, Column, Row, SEO, WorkList } from 'components';
import { getAsset, getEndDate } from 'utils';
import { INTERNAL_LINK_MAP } from 'utils/constants';

const WORK_LIST_PROPS = {
  columns: [
    {
      key: 'title',
      label: 'Title',
      data: ({ title }) => title,
      grow: 2,
      large: true,
    },
    {
      key: 'year',
      label: 'Year',
      data: ({ timeline }) => getEndDate(timeline).getFullYear(),
    },
  ],
  link: ({ primaryLink, slug }) => primaryLink || INTERNAL_LINK_MAP.work({ slug }),
  sort: (a, b) => {
    const aEndDate = getEndDate(a.timeline);
    const bEndDate = getEndDate(b.timeline);
    if (aEndDate > bEndDate) {
      return -1;
    } else if (aEndDate < bEndDate) {
      return 1;
    }
    return a.title.localeCompare(b.title);
  },
};

const Work = ({
  data: {
    caseStudies: { caseStudies },
    otherWork: { otherWork },
    page: { caseStudiesTitle, otherWorkTitle, seo },
  },
}) => {
  const [caseStudyIndex, setCaseStudyIndex] = useState(-1);
  const [otherWorkIndex, setOtherWorkIndex] = useState(-1);

  /**
   * Gets the url of a work's preview image.
   *
   * @param {object} work a work object
   * @param {object} work.preview the preview image object
   * @returns {string|null} the work's preview image, or null
   * if the work has no preview
   */
  const getPreviewImage = ({ preview }) => getAsset(preview);

  const caseStudyImgs = useMemo(() => caseStudies.map(getPreviewImage), []);
  const otherWorkImgs = useMemo(() => otherWork.map(getPreviewImage), []);

  return (
    <Column>
      <SEO {...seo} path="/work" />
      <Row>
        <Block grow padded title={caseStudiesTitle}>
          <BlockPreview images={caseStudyImgs} index={caseStudyIndex}>
            <WorkList {...WORK_LIST_PROPS} list={caseStudies} onIndexChange={setCaseStudyIndex} />
          </BlockPreview>
        </Block>
      </Row>
      <Row grow>
        <Block forceGrow padded title={otherWorkTitle}>
          <BlockPreview images={otherWorkImgs} index={otherWorkIndex}>
            <WorkList {...WORK_LIST_PROPS} list={otherWork} onIndexChange={setOtherWorkIndex} />
          </BlockPreview>
        </Block>
      </Row>
    </Column>
  );
};

Work.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
};

export default Work;

export const query = graphql`
  fragment workIndexFragment on SanityWork {
    preview {
      asset {
        url
      }
    }
    slug {
      current
    }
    timeline {
      endDate
    }
    title
  }

  query workPageQuery {
    page: sanityWorkPage {
      seo {
        ...SEO
      }
      caseStudiesTitle
      otherWorkTitle
    }

    caseStudies: allSanityWork(
      filter: { isCaseStudy: { eq: true } }
      sort: { order: DESC, fields: timeline___endDate }
    ) {
      caseStudies: nodes {
        ...workIndexFragment
      }
    }

    otherWork: allSanityWork(
      filter: { isCaseStudy: { eq: false } }
      sort: { order: DESC, fields: timeline___endDate }
    ) {
      otherWork: nodes {
        ...workIndexFragment
        primaryLink
      }
    }
  }
`;
