import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import imagesLoaded from 'imagesloaded';
import { useArrayRef, usePrevious } from 'hooks';
import { className } from 'utils';
import styles from './blockPreview.module.scss';

const BlockPreview = ({ children, images, index, isStatic }) => {
  const [imagesRef, setImageRef] = useArrayRef();

  const [isLoaded, setIsLoaded] = useState(false);
  const [image, setImage] = useState('');

  const lastIndex = usePrevious(index);

  useEffect(() => {
    if (isLoaded) {
      let image = '';
      if (index >= 0 || lastIndex >= 0) {
        const imageIdx = index >= 0 ? index : lastIndex;
        image = images[imageIdx];
      }
      if (image) {
        setImage(image);
      }
    }
  }, [index, isLoaded]);

  useEffect(() => {
    if (imagesRef.current) {
      imagesLoaded(imagesRef.current, () => {
        setIsLoaded(true);
      });
    }
  }, []);

  return (
    <Fragment>
      {!isLoaded && (
        <ul className={styles.hidden}>
          {images.map(
            (src, i) =>
              src && (
                <li key={i}>
                  <img ref={setImageRef(i)} src={src} alt={`Loading ${src}`} />
                </li>
              ),
          )}
        </ul>
      )}
      <div className={styles.previewContainer}>
        <div {...className(styles.previewInner, isStatic && styles.previewInnerStatic)}>
          <div
            {...className(
              styles.preview,
              isStatic && styles.previewStatic,
              index < 0 && styles.previewHidden,
            )}>
            <div
              {...className(
                styles.previewImage,
                index >= 0 && images[index] && styles.previewImageVisible,
              )}
              style={image ? { backgroundImage: `url(${image})` } : {}}
            />
          </div>
        </div>
      </div>
      <div className={styles.main}>{children}</div>
    </Fragment>
  );
};

BlockPreview.propTypes = {
  children: PropTypes.node,
  images: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.number,
  isStatic: PropTypes.bool,
};

BlockPreview.defaultProps = {
  images: [],
  index: -1,
  isStatic: false,
};

export default BlockPreview;
