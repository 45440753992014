import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
  ArrowLink,
  Block,
  BlockPreview,
  Column,
  HoverList,
  Link,
  Row,
  SEO,
  WorkBody,
  WorkMeta,
  WorkProgressIndicator,
} from 'components';
import { className, getAsset, getLinkUrl } from 'utils';
import { INTERNAL_LINK_MAP } from 'utils/constants';
import styles from './work.module.scss';

const Work = ({
  data: {
    work: {
      body,
      externalLinks,
      isCaseStudy,
      isWorkInProgress,
      origin,
      originRole,
      preview,
      relatedWork,
      seo,
      services,
      slug,
      team,
      title,
      timeline,
    },
  },
}) => {
  const previewUrl = useMemo(() => getAsset(preview), [preview]);
  return (
    <Column>
      <SEO {...seo} path={INTERNAL_LINK_MAP.work({ slug })} />
      <Row>
        <Column>
          <div {...className(styles.block, styles.blockMain)}>
            <BlockPreview images={[previewUrl]} index={0} isStatic>
              <div className={styles.meta}>
                <p className={styles.label}>{isCaseStudy ? 'Case Study' : 'Work'}</p>
                <h1 className={styles.title}>{title}</h1>
                {externalLinks && !!externalLinks.length && (
                  <ul className={styles.linkList}>
                    {externalLinks.map(({ internalLink, text, url }, i) => (
                      <li key={i} className={styles.linkItem}>
                        <ArrowLink to={getLinkUrl(internalLink, url)} text={text} newTab />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </BlockPreview>
          </div>
          <div className={styles.block}>
            <WorkMeta
              origin={origin}
              originRole={originRole}
              services={services}
              team={team}
              timeline={timeline}
            />
          </div>
        </Column>
      </Row>
      <Row grow>
        <Block {...className(styles.body, isWorkInProgress && styles.bodyWip)} forceGrow>
          <div className={styles.bodyMain}>
            <WorkBody body={body} />
          </div>
          {isWorkInProgress && <WorkProgressIndicator />}
        </Block>
      </Row>
      {relatedWork && !!relatedWork.length && (
        <Row>
          <Block className={styles.related} title="Related Work" padded>
            <HoverList>
              {({ listRef, setItemRef, selectedIndex }) => (
                <ul ref={listRef} className={styles.relatedList}>
                  {relatedWork.map(({ slug, title }, i) => (
                    <li className={styles.relatedItem} key={slug.current}>
                      <Link
                        {...className(
                          styles.relatedLink,
                          selectedIndex >= 0 && selectedIndex !== i && styles.relatedLinkInactive,
                        )}
                        to={INTERNAL_LINK_MAP.work({ slug })}
                        ref={setItemRef(i)}>
                        <h2>{title}</h2>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </HoverList>
          </Block>
        </Row>
      )}
    </Column>
  );
};

Work.propTypes = {
  data: PropTypes.shape({
    work: PropTypes.object.isRequired,
  }),
};

export default Work;

export const query = graphql`
  query workQuery($slug: String!) {
    work: sanityWork(slug: { current: { eq: $slug } }) {
      body: _rawBody(resolveReferences: { maxDepth: 15 })
      externalLinks {
        ...Link
      }
      isCaseStudy
      isWorkInProgress
      origin {
        __typename
        ... on SanityCourse {
          title
          listing
        }
        ... on SanityJob {
          role
          company {
            name
            websiteUrl
          }
        }
      }
      originRole: role
      preview {
        asset {
          url
        }
      }
      relatedWork {
        title
        slug {
          current
        }
      }
      seo {
        ...SEO
      }
      services {
        name
      }
      slug {
        current
      }
      team {
        name
        websiteUrl
      }
      timeline {
        startDate
        endDate
      }
      title
    }
  }
`;
