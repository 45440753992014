import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { getDateRange } from 'utils';
import { SANITY_TYPES } from 'utils/constants';
import styles from './meta.module.scss';

const WorkMeta = ({ origin, originRole, services, team, timeline }) => {
  const dates = useMemo(() => getDateRange(timeline), [timeline]);
  const role = useMemo(() => {
    if (!origin) {
      return null;
    } else if (origin.__typename === SANITY_TYPES.COURSE) {
      return {
        role: 'Student in',
        text: origin.title,
        url: origin.listing,
      };
    } else if (origin.__typename === SANITY_TYPES.JOB) {
      const { company } = origin;
      return {
        role: `${originRole || origin.role} at`,
        text: company.name,
        url: company.websiteUrl,
      };
    }
    return null;
  }, [origin, originRole]);

  return (
    <ul className={styles.table}>
      {role && (
        <li className={styles.tableItem}>
          <p className={styles.label}>Role</p>
          <p className={styles.content}>
            {role.role}{' '}
            <Link className={styles.role} to={role.url} newTab>
              {role.text}
            </Link>
          </p>
        </li>
      )}
      <li className={styles.tableItem}>
        <p className={styles.label}>Dates</p>
        <p className={styles.content}>{dates}</p>
      </li>
      {team && !!team.length && (
        <li className={styles.tableItem}>
          <p className={styles.label}>Team</p>
          <ul className={styles.content}>
            {team.map(({ name, websiteUrl }, i) => {
              let person = <span>{name}</span>;
              if (websiteUrl) {
                person = (
                  <Link to={websiteUrl} newTab>
                    {name}
                  </Link>
                );
              }
              return (
                <li key={i} className={styles.listItem}>
                  {person}
                  {i < team.length - 1 && ', '}
                </li>
              );
            })}
          </ul>
        </li>
      )}
      {services && !!services.length && (
        <li className={styles.tableItem}>
          <p className={styles.label}>What I did</p>
          <ul className={styles.content}>
            {services.map(({ name }, i) => (
              <li key={i} className={styles.listItem}>
                {name}
                {i < services.length - 1 && ', '}
              </li>
            ))}
          </ul>
        </li>
      )}
    </ul>
  );
};

WorkMeta.propTypes = {
  origin: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    /* Course */
    title: PropTypes.string,
    listing: PropTypes.string,
    /* Job */
    role: PropTypes.string,
    company: PropTypes.shape({
      name: PropTypes.string,
      websiteUrl: PropTypes.string,
    }),
  }),
  originRole: PropTypes.string,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  team: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      websiteUrl: PropTypes.string,
    }),
  ),
  timeline: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
  }).isRequired,
};

export default WorkMeta;
