const CURRENT_DATE = new Date();

/**
 * Returns either the end date from a given timeline, or
 * the current date if it doesn't exist.
 *
 * @param {object} [timeline] a Sanity timeline object
 * @returns {Date} the end date of a work
 */
const getEndDate = timeline =>
  timeline && timeline.endDate ? new Date(timeline.endDate) : CURRENT_DATE;

export default getEndDate;
