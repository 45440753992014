import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Content } from 'components';
import { Caution } from 'svgs';
import styles from './progressIndicator.module.scss';

const WorkProgressIndicator = () => {
  const {
    dictionary: { body, title },
  } = useStaticQuery(graphql`
    query workWipIndicatorQuery {
      dictionary: sanityDictionary {
        body: _rawWorkWipBody
        title: workWipTitle
      }
    }
  `);

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.icon}>
          <Caution />
        </div>
        <h5 className={styles.title}>{title}</h5>
        <Content className={styles.body} blocks={body} />
      </div>
    </div>
  );
};

export default WorkProgressIndicator;
