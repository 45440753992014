import { useRef, useState } from 'react';
/**
 * React hook that provides a similar API to the `useState`
 * hook, but performs updates using refs instead of asynchronous
 * actions.
 *
 * @param initialState the initial state of the state variable
 * @returns an array containing the state variable, a setter
 * function for the state, and a ref of the state variable
 */
const useStateRef = initialState => {
  const state = useRef(initialState);
  const [, setTick] = useState(0);

  const setState = newState => {
    if (typeof newState === 'function') {
      state.current = newState(state.current);
    } else {
      state.current = newState;
    }
    setTick(tick => tick + 1);
  };

  return [state.current, setState, state];
};

export default useStateRef;
