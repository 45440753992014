import { useEffect, useRef } from 'react';

/**
 * React hook that stores and returns the previous
 * value of a given variable.
 *
 * It should be noted that it will be initialized with
 * the current value of the given variable.
 *
 * @param {any} val the value to store the history of
 * @returns the previous value of the variable
 */
const usePrevious = val => {
  const ref = useRef(val);

  useEffect(() => {
    ref.current = val;
  });

  return ref.current;
};

export default usePrevious;
