import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, TextWithArrow } from 'components';
import { className } from 'utils';
import styles from './arrowLink.module.scss';

const ArrowLink = forwardRef(
  ({ children, className: customClassName, inline, large, newTab, small, text, to }, ref) => {
    const Tag = useMemo(() => (inline ? 'span' : large ? 'h4' : 'p'), [inline, large]);
    return (
      <Link
        {...className(styles.link, inline && styles.linkInline, customClassName)}
        to={to}
        newTab={newTab}
        aria-label={text}
        ref={ref}>
        <Tag
          {...className(
            styles.textWrapper,
            large && styles.textWrapperLarge,
            small && styles.textWrapperSmall,
          )}>
          <TextWithArrow
            {...className(styles.arrow, large && styles.arrowLarge, small && styles.arrowSmall)}
            small={small}>
            {text}
          </TextWithArrow>
        </Tag>
        {children}
      </Link>
    );
  },
);

ArrowLink.displayName = 'ArrowLink';

ArrowLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  inline: PropTypes.bool,
  large: PropTypes.bool,
  newTab: PropTypes.bool,
  small: PropTypes.bool,
  text: PropTypes.string,
  to: PropTypes.string.isRequired,
};

ArrowLink.defaultProps = {
  inline: false,
  large: false,
  newTab: false,
  small: false,
  text: '',
};

export default ArrowLink;
