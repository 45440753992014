import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Arrow } from 'svgs';
import { className } from 'utils';
import styles from './textWithArrow.module.scss';

const TextWithArrow = ({ children, className: customClassName, small, splitBy }) => {
  const [mainText, lastSection] = useMemo(() => {
    const sections = (children || '').split(splitBy);
    return [sections.slice(0, -1).join(splitBy), sections.slice(-1)];
  }, [children]);

  return (
    <Fragment>
      {mainText}
      {splitBy}
      <span className={styles.wrapper}>
        {lastSection}
        <span {...className(styles.arrow, customClassName)}>
          <Arrow external small={small} />
        </span>
      </span>
    </Fragment>
  );
};

TextWithArrow.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  small: PropTypes.bool,
  splitBy: PropTypes.string,
};

TextWithArrow.defaultProps = {
  small: false,
  splitBy: ' ',
};

export default TextWithArrow;
