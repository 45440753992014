import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Defs } from 'svgs';
import 'style/index.scss';

const wrapRootElement = ({ element }) => (
  <Fragment>
    <Defs />
    {element}
  </Fragment>
);

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};

export default wrapRootElement;
