/**
 * Retrieves the url of the asset, or returns null
 * if asset doesn't exist.
 *
 * @param {object} image the image to fetch the url for
 * @returns {string|null} the url of the asset, or `null`
 */
const getAsset = image => (image && image.asset ? image.asset.url : null);

export default getAsset;
