import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Highlight, { defaultProps } from 'prism-react-renderer';
import vsDarkTheme from 'prism-react-renderer/themes/vsDark';
import styles from './code.module.scss';

const LANGUAGES = {
  markup: 'HTML',
  scss: 'CSS/SCSS',
  javascript: 'JavaScript',
  jsx: 'JSX',
  typescript: 'TypeScript',
};

const Code = ({ node: { code, language } }) => {
  const langTitle = useMemo(() => LANGUAGES[language], [language]);

  const sanitizedCode = useMemo(() => code.trim(), [code]);
  const theme = useMemo(
    () => ({
      ...vsDarkTheme,
      plain: {
        ...vsDarkTheme.plain,
        backgroundColor: styles.varBackgroundColor,
      },
    }),
    [],
  );

  return (
    <div className={styles.container}>
      <header className={styles.header}>{langTitle}</header>
      <div className={styles.code}>
        <Highlight {...defaultProps} code={sanitizedCode} language={language} theme={theme}>
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div key={i} {...getLineProps({ line, key: i })}>
                  {line.map((token, j) => (
                    <span key={j} {...getTokenProps({ token, key: j })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
      </div>
    </div>
  );
};

Code.propTypes = {
  node: PropTypes.shape({
    code: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
};

export default Code;
