import Blockquote from './Blockquote';
import Code from './Code';
import Image from './Image';
import Slideshow from './Slideshow';

export default {
  blockquote: Blockquote,
  code: Code,
  imageWithMeta: Image,
  slideshow: Slideshow,
};
