import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import styles from './image.module.scss';

const Image = ({ node: { alt, asset, caption } }) => (
  <figure className={styles.container}>
    {asset && (
      <Link className={styles.imgLink} to={asset.url} newTab>
        <img className={styles.img} src={asset.url} alt={alt} />
      </Link>
    )}
    {caption && <figcaption className={styles.caption}>{caption}</figcaption>}
  </figure>
);

Image.propTypes = {
  node: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    asset: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    caption: PropTypes.string,
  }).isRequired,
};

export default Image;
